"use client";

let triggered = false;

async function enableApiMocking() {
  if (process.env.NEXT_RUNTIME !== "nodejs") {
    const { worker } = await import("./browser");
    await worker.start({
      onUnhandledRequest: "bypass",
    });
  }
}

export function MockProvider({ environment }: { environment: string }) {
  if (!triggered && environment === "development") {
    triggered = true;
    throw enableApiMocking();
  }

  return null;
}
