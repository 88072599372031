"use client";
import { type CSSVariablesResolver } from "@mantine/core";

export const resolver: CSSVariablesResolver = () => {
  return {
    variables: {},
    dark: {
      "--mantine-color-body": "rgb(23 23 23)",
    },
    light: {},
  };
};
